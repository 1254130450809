<template>
  <div class="mx-auto">
    <TitleWrapper
      :filter-option="false"
      title="GEN_SETTS"
      tooltip-title="GEN_SETTS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
    <div
      v-if="!isLoading"
      class="min-h-screen settings-bar pt-7 mt-3 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <!-- Teacher Attendance Permission -->

      <div
        class="flex justify-between sm:gap-7 gap-0 flex-col md:flex-row text-sm text-text-color font-roboto font-normal"
      >
        <InputBox
          v-model.trim="generalSetting.instituteName"
          type="text"
          :rules="`required`"
          name="Institute Name"
          tooltip-desc="toolTipInfo.INSTITUTE_NAME"
          :tooltip-prop="true"
          title="Institute Name"
          label="Institute Name"
          placeholder="write"
          class="flex-1"
          is-requried="true"
        />
        <InputBox
          v-model.trim="generalSetting.siteTitle"
          type="text"
          tooltip-desc="toolTipInfo.SITE_TITLE"
          name="SITE_TITLE"
          title="SITE_TITLE"
          :tooltip-prop="true"
          label="SITE_TITLE"
          rules="alpha_spaces"
          placeholder="SITE_TITLE"
          class="flex-1"
        />
      </div>

      <div
        class="flex justify-between sm:gap-7 gap-0 flex-col md:flex-row text-sm text-text-color font-roboto font-normal"
      >
        <div class="flex flex-1 flex-wrap">
          <div class="w-full flex justify-between">
            <div class="w-full flex flex-col">
              <InputBox
                id="input-type-file-logo"
                type="file"
                name="Logo"
                title="Logo"
                label="Logo"
                tooltip-desc="toolTipInfo.LOGO"
                :tooltip-prop="true"
                class="flex-1"
                :rules="`${!logoSrc ? 'required|' : ''}size:${imageSize}`"
                :file-src="logoSrc"
                :hidden="true"
                :is-component-in-loading="logoLoading"
                :disabled="logoLoading"
                placeholder="Choose File"
                @change="setLogo"
              />
            </div>
            <p class="pt-10 text-xs ml-4 text-text-color-grey">jpeg/png/jpg</p>
          </div>
          <p class="text-xs -mt-3 mb-3 text-text-color-grey">Ideally image should be 512 X 512</p>
        </div>
        <div class="flex-1 flex justify-between">
          <div class="w-full flex flex-col">
            <InputBox
              id="input-type-file-favicon"
              type="file"
              name="Favicon"
              :tooltip-prop="true"
              title="Favicon"
              label="Favicon"
              tooltip-desc="toolTipInfo.FAVICON"
              class="flex-1 border-none"
              :hidden="true"
              :rules="`${!faviconSrc ? 'required|' : ''}size:${imageSize}`"
              :file-src="faviconSrc"
              :disabled="faviconLoading"
              :is-component-in-loading="faviconLoading"
              placeholder="Choose File"
              @change="setFavicon"
            />
          </div>
          <p class="pt-10 text-xs ml-4 md:mr-4 text-text-color-grey">jpeg/png/jpg</p>
        </div>
      </div>
      <InputBox
        v-model.trim="generalSetting.siteUrl"
        type="text"
        name="GS_ESL"
        :disabled="generalSettingsData.is_site_url_approved"
        title="GS_ESL"
        label="GS_ESL"
        placeholder="GS_ESL"
        class="flex-1"
      />
      <div class="flex flex-wrap w-full md:w-3/6"></div>
      <div class="pb-5 md:pb-0">
        <button
          class="mt-5 mb-5 focus:outline-none focus:shadow-md ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
          @click.prevent="handleSubmit(apply(invalid))"
        >
          {{ $t('dashboard.Save') }}
        </button>
      </div>
    </div>
    <div
      v-else
      class="settings-bar min-h-screen pt-7 mt-7 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <Loader :content="true" />
    </div>
    <!-- </ValidationObserver> -->
  </div>
</template>

<script>
/* COMPONENTS */
import TitleWrapper from '@components/TitleWrapper.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { mapState, mapActions } from 'vuex'

import generalConstants from '@src/constants/general-constants.js'
/* STORE */

export default {
  components: {
    TitleWrapper,
    InputBox,
    // ValidationObserver,
    Loader,
  },
  beforeRouteLeave(to, from, next) {
    if (this.logoLoading || this.faviconLoading) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  page: {
    title: 'System Settings | General Settings',
    meta: [
      {
        name: 'description',
        content: 'General Settings',
      },
    ],
  },
  data() {
    return {
      generalSetting: {
        instituteName: '',
        siteTitle: '',
        siteUrl: '',
      },
      dashboard: 'dashboard',
      imageSize: generalConstants.imageSize,
      logoLoading: false,
      faviconLoading: false,
      generalSettingsData: [],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.layout.isLoading,
      logoSrc: (state) => state.settings.generalSettingLogoURL,
      faviconSrc: (state) => state.settings.generalSettingFaviconURL,
    }),
  },
  created() {
    this.generalSetting.siteTitle = this.$store.state.settings.generalSettingSiteTitle || 'MyQampus'
    this.generalSetting.instituteName = this.$store.state.settings.generalSettingInstituteName
  },
  mounted() {
    this.getSettings()
  },
  methods: {
    async setLogo(file, source, name) {
      if (file.size / 1024 > this.imageSize) return
      const formData = new FormData()
      formData.append('image', file)
      this.logoLoading = true
      const [res, err] = await this.setLogoAndFavicon({ formData, type: 'logo' })
      this.logoLoading = false
      this.$store.commit('settings/GS_SET_LOGO', res.data.image)
    },
    async setFavicon(file, source, name, invalid) {
      if (file.size / 1024 > this.imageSize) return
      const formData = new FormData()
      formData.append('favicon', file)
      this.faviconLoading = true
      const [res, err] = await this.setLogoAndFavicon({ formData, type: 'favicon' })
      this.faviconLoading = false
      this.$store.commit('settings/GS_SET_FAVICON', res.data.favicon)
    },
    async apply(invalid) {
      if (!invalid && !this.logoLoading && !this.faviconLoading) {
        const data = {
          title: this.generalSetting.instituteName,
          site_title: this.generalSetting.siteTitle,
          site_url: this.generalSetting.siteUrl,
        }
        const [res, err] = await this.setInstituteNameAndTitle(data)
        if (!err) {
          this.$store.commit('settings/GS_SET_INSTITUTE_NAME', res.data.title)
          this.$store.commit('settings/GS_SET_SITE_TITLE', res.data.site_title)
        }
      }
    },
    getSettings() {
      this.getGeneralSettings().then((res) => {
        this.generalSettingsData = res?.data
        this.generalSetting.siteUrl = res?.data?.site_url
        this.generalSetting.instituteName = res?.data?.title
      })
    },
    ...mapActions('settings', [
      'setDefaultSettings',
      'setLogoAndFavicon',
      'setInstituteNameAndTitle',
      'getGeneralSettings',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.settings-bar {
  background-color: var(--bg-color-white);
}

.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: #ccc;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.slider::before {
  position: absolute;
  bottom: 3px;
  left: 3px;
  width: 17px;
  height: 16px;
  content: '';
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
input:checked + .slider {
  background-color: var(--primary-green);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-green);
}
input:checked + .slider::before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}
.previewImage {
  width: 100%;
  min-width: 100px;
  max-width: 300px;
  height: 100%;
  min-height: 100px;
  max-height: 300px;
  border-radius: 35px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round::before {
  border-radius: 50%;
}
</style>
